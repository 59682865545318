import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Mail, MapPin, Phone } from 'react-feather';
import { useIntl } from 'react-intl';
import Link from 'utils/link';
import menuItems from 'utils/menuitems';
import Container from './ui/container';

export default function Footer() {
    const intl = useIntl();

    const query: GatsbyTypes.FooterQuery = useStaticQuery<GatsbyTypes.FooterQuery>(graphql`
        query Footer {
            allMdx(filter: { fields: { sourceName: { eq: "options" } } }) {
                nodes {
                    fields {
                        locale
                    }
                    frontmatter {
                        footerDescription
                        location
                        location2
                        email
                        phone
                        phone2
                    }
                }
            }
        }
    `);

    const quickLinks = menuItems.map((item) => (
        <li key={`footer-ql-${item.to}`}>
            <Link to={item.to} title={item.title}>
                {intl.formatMessage({
                    id: `${item.title.toLowerCase()}`,
                })}
            </Link>
            {item.sub && (
                <ul className="my-2">
                    {item.sub.map((item) => (
                        <li key={`footer-ql-${item.to}`}>
                            <Link to={item.to} title={item.title}>
                                {intl.formatMessage({
                                    id: `${item.title
                                        .split(' ')
                                        .join('')
                                        .toLowerCase()}`,
                                })}
                            </Link>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    ));

    const data = query.allMdx?.nodes.find(
        (node) => node.fields?.locale === intl.locale
    )?.frontmatter;

    return (
        <footer className="bg-darkbg text-white">
            <Container>
                <div className="pt-6">
                    <div className="flex flex-wrap">
                        <div className="w-full md:w-1/2 lg:w-1/3 p-3">
                            <StaticImage
                                src="../images/logo_white.png"
                                alt="AMDTEC"
                                width={200}
                                className="w-36 lg:w-auto"
                            />
                            <p className="mt-3">{data?.footerDescription}</p>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/3 p-3 lg:pl-24">
                            <h3 className="font-semibold text-2xl mb-5 mt-3">
                                {intl.formatMessage({
                                    id: 'quickLinks',
                                })}
                            </h3>
                            <ul>{quickLinks}</ul>
                        </div>
                        <div className="w-full md:w-1/2 lg:w-1/3 p-3">
                            <h3 className="font-semibold text-2xl mb-5 mt-3">
                                {intl.formatMessage({
                                    id: 'contactUs',
                                })}
                            </h3>
                            <div>
                                <ContactItem
                                    icon={<MapPin />}
                                    data={data?.location as string}
                                />
                                <ContactItem
                                    icon={<MapPin />}
                                    data={data?.location2 as string}
                                />
                                <ContactItem
                                    icon={<Phone />}
                                    type="phone"
                                    data={data?.phone as string}
                                />
                                <ContactItem
                                    icon={<Mail />}
                                    type="email"
                                    data={data?.email as string}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="bg-redbg opacity-60 w-full my-6"
                    style={{ height: '1px' }}
                ></div>
                <p className="text-center pb-4">
                    Copyright &copy; {new Date().getFullYear()} AMDTEC
                </p>
            </Container>
        </footer>
    );
}

const ContactItem = ({
    icon,
    data,
    type,
}: {
    icon: JSX.Element;
    data: string;
    type?: 'email' | 'phone' | 'phone2';
}) => {
    return (
        <div className="flex flex-wrap items-center">
            <div className="py-2">
                {React.cloneElement(icon, {
                    size: 18,
                })}
            </div>
            <p className="whitespace-pre-wrap py-2 px-2">
                {type ? (
                    <a
                        href={
                            type === 'email' ? `mailto:${data}` : `tel:${data}`
                        }
                    >
                        {data}
                    </a>
                ) : (
                    data
                )}
            </p>
        </div>
    );
};
