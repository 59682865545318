import React, { useEffect, useRef, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useLocation } from '@reach/router';
import { Menu } from 'react-feather';
import { useIntl } from 'react-intl';
import Link from 'utils/link';
import menuItems from 'utils/menuitems';
// import LangSwitcher from './langswitcher';

export default function Navbar({
    showPlaceholder,
    isHome,
}: {
    showPlaceholder?: boolean;
    isHome?: boolean;
}) {
    const location = useLocation();
    const intl = useIntl();

    const navItems = menuItems.map((item) => (
        <NavItem
            {...item}
            active={
                item.to === '/'
                    ? location.pathname ===
                      (intl.locale === 'en' ? '/' : `/${intl.locale}/`)
                    : location.pathname.startsWith(
                          intl.locale === 'en'
                              ? item.to
                              : `/${intl.locale}${item.to}`
                      )
            }
            key={`main-nav-${item.title}-${item.to}`}
        />
    ));

    const navItemsMobile = menuItems.map((item) => (
        <NavItem
            {...item}
            active={
                item.to === '/'
                    ? location.pathname ===
                      (intl.locale === 'en' ? '/' : `/${intl.locale}/`)
                    : location.pathname.startsWith(
                          intl.locale === 'en'
                              ? item.to
                              : `/${intl.locale}${item.to}`
                      )
            }
            key={`main-nav-m-${item.title}-${item.to}`}
            liClassName={`block w-full p-2 menu-item-mobile`}
        />
    ));

    const [menuOpen, setMenuOpen] = useState(false);
    const [scrolled, _setScrolled] = useState(false);
    const scrolledRef = useRef(false);

    const setScrolled = (v: boolean) => {
        _setScrolled(v);
        scrolledRef.current = v;
    };

    const onScroll = () => {
        if (window.scrollY > 400 && !scrolledRef.current) {
            setScrolled(true);
        } else if (window.scrollY < 400 && scrolledRef.current) {
            setScrolled(false);
        }
    };

    useEffect(() => {
        onScroll();

        window.addEventListener('scroll', onScroll);

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    return (
        <React.Fragment>
            <nav
                className={`w-full z-50 ${
                    scrolled
                        ? ' bg-white scrolled fixed shadow bg-opacity-60 glassy'
                        : 'absolute'
                }`}
            >
                <div className="hidden lg:flex justify-between max-w-7xl mx-auto px-16 py-2">
                    <Link to="/" title="AMDTEC">
                        {isHome && !scrolled ? (
                            <StaticImage
                                src={`../images/logo_white.png`}
                                alt="AMDTEC Logo"
                                height={40}
                                placeholder="tracedSVG"
                            />
                        ) : (
                            <StaticImage
                                src={`../images/logo.png`}
                                alt="AMDTEC Logo"
                                height={40}
                                placeholder="tracedSVG"
                            />
                        )}
                    </Link>
                    <div
                        className={`flex items-center  ${
                            isHome && !scrolled ? 'text-white' : 'text-black'
                        }`}
                    >
                        <ul className={`flex items-center`}>{navItems}</ul>
                    </div>
                </div>
                <div className="flex lg:hidden justify-between p-4 relative items-center">
                    <Link to="/" title="AMDTEC">
                        {isHome && !scrolled ? (
                            <StaticImage
                                src={`../images/logo_white.png`}
                                alt="AMDTEC Logo"
                                height={40}
                                placeholder="tracedSVG"
                            />
                        ) : (
                            <StaticImage
                                src={`../images/logo.png`}
                                alt="AMDTEC Logo"
                                height={40}
                                placeholder="tracedSVG"
                            />
                        )}
                    </Link>
                    <div>
                        <button
                            className={`border rounded border-gray-500 p-1 ${
                                isHome && !scrolled && 'text-white'
                            }`}
                            onClick={() => {
                                setMenuOpen(!menuOpen);
                            }}
                        >
                            <Menu />
                        </button>
                        {menuOpen && (
                            <React.Fragment>
                                <ul
                                    className={`text-black w-full absolute left-0 bg-white py-2`}
                                >
                                    {navItemsMobile}
                                    <li className="mx-4 py-4 hidden"></li>
                                </ul>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </nav>
            {showPlaceholder && <div className="h-28"></div>}
        </React.Fragment>
    );
}

interface NavItemProps {
    title: string;
    to: string;
    active?: boolean;
    liClassName?: string;
    sub?: NavItemProps[];
}

const NavItem = ({ title, to, active, liClassName, sub }: NavItemProps) => {
    const intl = useIntl();
    return (
        <li className={`mx-4 menu-item relative ${liClassName} py-3`}>
            <Link
                to={to}
                className={`${
                    active && 'text-redbg'
                } hover:text-redbg transition-colors duration-300`}
            >
                {intl.formatMessage({
                    id: `${title.toLowerCase()}`,
                })}
            </Link>
            {sub && <SubMenu items={sub} />}
        </li>
    );
};

const SubMenu = ({ items }: { items: NavItemProps[] }) => {
    const intl = useIntl();

    return (
        <ul className="submenu absolute w-52 border-gray-100 border bg-white p-4 right-0 top-10">
            {items.map((item) => {
                return (
                    <li className="list-none my-3 text-black " key={item.to}>
                        <Link
                            className="hover:text-redbg block hover:bg-gray-100 p-2"
                            to={item.to}
                        >
                            {intl.formatMessage({
                                id: `${item.title
                                    .split(' ')
                                    .join('')
                                    .toLowerCase()}`,
                            })}
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
};
