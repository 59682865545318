import React from 'react';
// @ts-ignore
import { LocalizedLink } from 'gatsby-theme-i18n';
import { GatsbyLinkProps } from 'gatsby';

export default function Link(
    props: GatsbyLinkProps<{}> & { language?: string }
) {
    return <LocalizedLink {...props} />;
}
