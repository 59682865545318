import React, { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export default function SEO({
    description,
    title,
    children,
    image,
}: {
    title: string;
    description?: string;
    children?: ReactNode;
    image?: string;
}) {
    const seoData = useStaticQuery<GatsbyTypes.SEOQuery>(
        graphql`
            query SEO {
                site: site {
                    siteMetadata {
                        title
                        author
                    }
                }
                options: mdx(fields: { sourceName: { eq: "options" } }) {
                    frontmatter {
                        description
                        seoImage {
                            publicURL
                        }
                    }
                }
            }
        `
    );

    const metaDescription =
        description || seoData.options?.frontmatter?.description;
    const metaImage =
        image ?? seoData.options?.frontmatter?.seoImage?.publicURL;

    return (
        <Helmet
            title={
                title === seoData.site?.siteMetadata?.title
                    ? title
                    : `${title} | ${seoData.site?.siteMetadata?.title}`
            }
        >
            <meta name="description" content={metaDescription} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={metaImage} />
            <meta name="twitter:card" content="summary" />
            <meta
                name="twitter:creator"
                content={seoData.site?.siteMetadata?.author}
            />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={metaDescription} />
            <meta name="twitter:image" content={metaImage} />
            {children}
        </Helmet>
    );
}
