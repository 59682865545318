const menuItems = [
    {
        title: 'HOME',
        to: '/',
    },
    {
        title: 'ABOUT',
        to: '/about',
    },
    {
        title: 'PRODUCTS',
        to: '/products',
        sub: [
            {
                title: 'RUGGED DISPLAYS / MONITORS',
                to: '/categories/rugged-displays',
            },
            {
                title: 'MILITARY RACKMOUNT SERVERS',
                to: '/categories/military-rackmount-servers',
            },
            {
                title: 'MILITARY COMPUTERS',
                to: '/categories/military-computers',
            },
        ],
    },
    {
        title: 'CONTACT',
        to: '/contact',
    },
];

export default menuItems;
